export default {
	canUseCdn: (state) => {
		return state.canUseCdn;
	},

	cdnUrl: (state) => {
		return state.cdnUrl;
	},

	usesCdn: (state) => {
		return state.usesCdn;
	},
};
