import defaultState from './default-state';

import blockHandler from './block-handler';
import stateHandler from './state-handler';
import variableHandler from './variable-handler';

export default {
	determineGroupEnd,
	computeThoroughStepsCompletionRatio,
	computeApproximativeStepsCompletionRatio,
	countStepsLeft,
	assignCursor,
	advance,
	goBack,
	skip,
};

function computeThoroughStepsCompletionRatio(flow, startPointCursor) {
	const stepsLeft = countStepsLeft(flow, startPointCursor);
	const stepsInTotal = countStepsLeft(flow);
	// console.log(`1 - ${stepsLeft} / ${stepsInTotal}`);
	return 1 - stepsLeft / stepsInTotal;
}

// FIXME : This is a temporary solution that needs further work. I should be improved.
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
function computeApproximativeStepsCompletionRatio(flow, cursor) {

	// Initialize the ration
	// let ratio = 0;

	// const {
	// 	indexGroupEnd,
	// 	indexLoopStart,
	// 	indexPileStart,
	// 	lastPiledContentIndex,
	// 	totalNumberRepetitions,
	// } = cursor.navigation;
	// const { index, numberRepetition, piledContentIndex } = cursor.current;

	// // Set to the position on the start of the pile
	// const totalNumberIndexPositions = flow.length;
	// const currentPileStartPosition = (indexPileStart != defaultState.UNSET_INDEX) ? 
	// 	indexPileStart : 1;
	// const indexPositionUnitValue = 1 / totalNumberIndexPositions;
	// ratio += currentPileStartPosition * indexPositionUnitValue;

	// // Adjust the position for the number of piled elements completed
	// let blocksToReplicateCount = null;
	// let numberElementsInPile = null;
	// let positionInPile = null;
	// if (indexPileStart != defaultState.UNSET_INDEX) {
	// 	blocksToReplicateCount = indexGroupEnd - indexPileStart + 1;
	// 	numberElementsInPile = lastPiledContentIndex + 1;
	// 	positionInPile = index - indexPileStart;
	// } else {
	// 	blocksToReplicateCount = 1;
	// 	numberElementsInPile = 1;
	// 	positionInPile = 0;
	// }
	// const totalStepsInDepiling = blocksToReplicateCount * numberElementsInPile ;
	// const currentStepOfDepiling = blocksToReplicateCount * piledContentIndex + positionInPile;
	// ratio += currentStepOfDepiling / totalStepsInDepiling * indexPositionUnitValue;

	// // Adjust te position for the looped steps
	// let blocksInLoopCount = null;
	// let positionInLoop = null;
	// if (indexLoopStart != defaultState.UNSET_INDEX) {
	// 	blocksInLoopCount = indexGroupEnd - indexLoopStart;
	// 	positionInLoop = index - indexLoopStart;
	// } else {
	// 	blocksInLoopCount = 1;
	// 	positionInLoop = 0;
	// }

	// const unitValueForRepetitionSteps = indexPositionUnitValue / totalStepsInDepiling;
	// const totalStepsInLooping = blocksInLoopCount * totalNumberRepetitions;
	// const currentStepOfLooping = blocksInLoopCount * (numberRepetition - 1) + positionInLoop;
	// ratio += currentStepOfLooping / totalStepsInLooping * unitValueForRepetitionSteps;

	// return ratio;

	return cursor.current.index / flow.length;
}

function countStepsLeft(flow, startPointCursor) {
	// Deep copy the cursor (or initialize the cursor at the start by default)
	const stepTracerCursor = assignCursor(flow, startPointCursor);
	let stepsCounter = 0;

	while (!stepTracerCursor.flag.isBeyondEnd && !(blockHandler.getCurrentBlockType(flow, stepTracerCursor) === 'end')) {
		moveCursorNext(flow, stepTracerCursor);
		stepsCounter += 1;
	}
	return Math.max(0, stepsCounter);
}

function assignCursor(flow, cursorToCopy = null) {
	if (cursorToCopy) {
		return JSON.parse(JSON.stringify(cursorToCopy));
	} else {
		const defaultCursor = defaultState.DEFAULT_EXPERIMENT_STATE_CURSOR_VALUES();
		updateCursorNavigation(flow, defaultCursor);
		return defaultCursor;
	}
}

function advance(state, flow, cursor, isInitialized) {
	determineGroupEnd(flow, cursor);
	if (moveCursorSpecialCases(state, flow, cursor, isInitialized)) return;
	else moveCursorNext(flow, cursor, isInitialized);
	// determineGroupEnd(flow, cursor);
}

function goBack(flow, cursor, isInitialized) {
	determineGroupEnd(flow, cursor);
	moveCursorBack(flow, cursor, isInitialized);
}

function skip(state, flow, cursor, isInitialized) {
	if (moveCursorSpecialCases(state, flow, cursor, isInitialized)) return;
	do {
		moveCursorNext(flow, cursor, isInitialized);
		stateHandler.updateStateOnSkip(state, flow, cursor, isInitialized);
	} while (cursor.flag.isInSkipableChain);
	// determineGroupEnd(flow, cursor);
}

// Inner cursor move manipulations
function moveCursorSpecialCases(state, flow, cursor, isInitialized) {

	const currentBlock = blockHandler.getCurrentBlock(flow, cursor);

	// Successes for skipping loop was attained
	// We skip until we are out of the block group
	// const referenceSurveyAnswer = state.record.referenceSurveyAnswer;
	// if (referenceSurveyAnswer !== null) {
	// 	const mustJumpBecauseTooHigh = 
	// 		(currentBlock.jumpIfSurveyAnswerHigherThan ?? null) !== null && 
	// 		referenceSurveyAnswer > currentBlock.jumpIfSurveyAnswerHigherThan;
	// 	const mustJumpBecauseNoEqual = 
	// 		(currentBlock.jumpIfSurveyAnswerIsNot ?? null) !== null &&
	// 		currentBlock.jumpIfSurveyAnswerIsNot !== referenceSurveyAnswer;

	// 	if (mustJumpBecauseTooHigh || mustJumpBecauseNoEqual) {
	// 		moveCursorSkipBasedOnSurveyAnswer(state, flow, cursor, isInitialized);
	// 		return true;
	// 	}
	// }

	// Successes for skipping loop was attained
	// We skip until we are out of the block group
	if (state.record.successesInLoop >= currentBlock.successesForSkipLoop) {
		moveCursorSkipRepetions(state, flow, cursor, isInitialized);
		return true;
	}

	// Successes to not skip the "skip if minimal goal is not met" was not met :
	// We skip until we reach a block that does not have the "skipIfNotMetSuccessGoal"
	else if (state.record.previousSucessesInLoop < currentBlock.skipIfNotMetSuccessGoal) {
		moveCursorNotMetSuccessGoal(state, flow, cursor, isInitialized);
		return true;
	}

	// A skip on last repetition block is encountered
	// If we are at the last repetition, we skip the block
	else if (currentBlock.skipLoopOnLastRepetition && cursor.current.numberRepetition <= 1) {
		moveCursorSkipRepetions(state, flow, cursor, isInitialized);
		return true;
	} else return false;
}

function moveCursorNext(flow, cursor, isInitialized) {
	variableHandler.updateVariables(flow, cursor);
	performCursorDisplacementForward(flow, cursor, isInitialized);
	updateCursorNavigation(flow, cursor);
}

function moveCursorBack(flow, cursor, isInitialized) {
	variableHandler.updateVariables(flow, cursor);
	performCursorDisplacementBackward(cursor, isInitialized);
	updateCursorNavigation(flow, cursor);
}

function moveCursorSkipRepetions(state, flow, cursor, isInitialized) {
	do {
		moveCursorNext(flow, cursor, isInitialized);
		stateHandler.updateStateOnSkip(state, flow, cursor, isInitialized);
	} while (!cursor.flag.needsResetLoopParameters && !cursor.flag.isBeyondEnd);
}

function moveCursorNotMetSuccessGoal(state, flow, cursor, isInitialized) {
	do {
		moveCursorNext(flow, cursor, isInitialized);
		stateHandler.updateStateOnSkip(state, flow, cursor, isInitialized);
	} while (cursor.flag.isInSkipIfNotMetSuccessGoalChain && !cursor.flag.isBeyondEnd);
}

// function moveCursorSkipBasedOnSurveyAnswer(state, flow, cursor, isInitialized) {
// 	const referenceSurveyAnswer = state.record.referenceSurveyAnswer;
// 	const mustSkipBlock = () => {
// 		const currentBlock = blockHandler.getCurrentBlock(flow, cursor);
// 		const mustJumpBecauseTooHigh = 
// 			(currentBlock.jumpIfSurveyAnswerHigherThan ?? null) !== null && 
// 			referenceSurveyAnswer > currentBlock.jumpIfSurveyAnswerHigherThan;
// 		const mustJumpBecauseNoEqual = 
// 			(currentBlock.jumpIfSurveyAnswerIsNot ?? null) !== null &&
// 			currentBlock.jumpIfSurveyAnswerIsNot !== referenceSurveyAnswer;
// 		return mustJumpBecauseTooHigh || mustJumpBecauseNoEqual;
// 	}
// 	do {
// 		moveCursorNext(flow, cursor, isInitialized);
// 		stateHandler.updateStateOnSkip(state, flow, cursor, isInitialized);
// 	} while (cursor.flag.isInSkipableChain || mustSkipBlock()); 
// }

function performCursorDisplacementForward(flow, cursor, isInitialized = {}) {
	let needsResetLoopParameters = false;

	// Moving to the next inner step if there remains inner steps
	if (cursor.current.innerStepIndex < cursor.navigation.lastInnerStepsIndex) {
		cursor.current.innerStepIndex += 1;
		cursor.flag.isNewBlock = false;
		Object.assign(isInitialized, { content: false });
	}

	// Moving to a new block
	else if (cursor.navigation.indexNext < flow.length) {
		cursor.current.innerStepIndex = 0;

		// If the index of the next block is lower than or equal to the index of the current block, this means that we are looping
		if (cursor.navigation.indexNext <= cursor.current.index) {
			cursor.flag.isFirstIndexPassage = false;

			if (cursor.current.numberRepetition > 1) {
				cursor.current.numberRepetition -= 1;
			} else if (cursor.navigation.lastPiledContentIndex > 1) {
				cursor.navigation.lastPiledContentIndex -= 1;
				cursor.current.piledContentIndex += 1;
				needsResetLoopParameters = true; // Flag adjustment
			}
		}

		// Otherwise, if the next step is beyond a group of blocks, we reset the piled content index
		else if (cursor.navigation.indexNext > cursor.navigation.indexGroupEnd) {
			cursor.flag.isFirstIndexPassage = true;
			if (!cursor.navigation.mustMaintainPiledContentIndex)
				cursor.current.piledContentIndex = 0;
			needsResetLoopParameters = cursor.navigation.indexNext > cursor.navigation.indexGroupEnd; // Flag asjustment
		}

		// Indicate that we just moved in a new block
		cursor.flag.isNewBlock = true;

		// We move the current intdex to the next step
		cursor.current.index = cursor.navigation.indexNext;
		Object.assign(isInitialized, defaultState.IS_FULLY_NOT_INITIALIZED_STATUS());
	}

	// Moving beyond the last block of the flow
	else {
		cursor.flag.isBeyondEnd = true;
		Object.assign(isInitialized, defaultState.IS_FULLY_NOT_INITIALIZED_STATUS());
	}

	// Adjust the flags
	cursor.flag.needsResetLoopParameters = needsResetLoopParameters;
}

// THe backward mobility only exists for inner steps
function performCursorDisplacementBackward(cursor, isInitialized = {}) {
	// By moving backward, we are necessarily not beyond the last step
	cursor.flag.isBeyondEnd = false;

	// Moving to the previous inner step if there remains inner steps
	if (cursor.current.innerStepIndex > 0) {
		cursor.current.innerStepIndex -= 1;
		cursor.current.innerStepIndex = Math.max(0, cursor.current.innerStepIndex);
		Object.assign(isInitialized, { content: false });
	}
}

function updateCursorNavigation(flow, cursor) {
	// Parsing the block's flow navigation parameters
	const currentBlock = blockHandler.getCurrentBlock(flow, cursor);
	const {
		// Content arrays
		textContent,
		pictureFileName,
		midiFileName,
		videoFileName,
		referenceKeyboardKeys,
		audioFirst,
		audioSecond,
		maxStackedContent,

		// Cursor parameters
		numberRepetition,
		followedBy,
		loopEnd,
		isInSkipableChain,
		isInSkipIfNotMetSuccessGoalChain,
	} = currentBlock;

	// Set the current index parameter
	cursor.flag.isInSkipableChain = typeof isInSkipableChain === 'boolean' ? isInSkipableChain : false;
	cursor.flag.isInSkipIfNotMetSuccessGoalChain = typeof isInSkipIfNotMetSuccessGoalChain === 'boolean' ? isInSkipIfNotMetSuccessGoalChain : false;

	// Set all the navigation parameters
	setCursorInnerStepsTotal(cursor, textContent, pictureFileName);
	setCursorLoopStart(cursor, numberRepetition);
	setCursorMediaDepilingStart(
		cursor, 
		[
			midiFileName, 
			videoFileName, 
			textContent, 
			pictureFileName, 
			referenceKeyboardKeys, 
			audioFirst, 
			audioSecond, 
		],
		maxStackedContent
	);
	setCursorNextStep(cursor, followedBy, loopEnd);
}

function setCursorInnerStepsTotal(cursor, textContent, pictureFileName) {
	let innerStepsTextContent = defaultState.UNSET_INDEX;
	if (Array.isArray(textContent)) {
		const currentTextContent = textContent[cursor.current.piledContentIndex];
		innerStepsTextContent = Array.isArray(currentTextContent) ? currentTextContent.length - 1 : defaultState.UNSET_INDEX;
	}

	let innerStepsPictureFile = defaultState.UNSET_INDEX;
	if (Array.isArray(pictureFileName)) {
		const currentPictureFile = pictureFileName[cursor.current.piledContentIndex];
		innerStepsPictureFile = Array.isArray(currentPictureFile) ? currentPictureFile.length - 1 : defaultState.UNSET_INDEX;
	}

	const maxNumberContentElement = Math.max(
		innerStepsTextContent || 0, 
		innerStepsPictureFile || 0,
	);
	cursor.navigation.lastInnerStepsIndex = maxNumberContentElement;
}

// Let A, B, C and D be three blocks, that are not instruction blocks.
//
//              A                           B                           C                           D
//  -------------------------   -------------------------   -------------------------   -------------------------
//  | type: XYZ             |   | type: XYZ             |   | type: XYZ             |   | type: XYZ             |
//  |           ...         |   |           ...         |   |           ...         |   |           ...         |
//  | numberRepetition: 3   |   |                       |   | numberRepetition: 3   |   |                       |
//  | folloedBy: true       |   | folloedBy: true       |   |                       |   |                       |
//  -------------------------   -------------------------   -------------------------   -------------------------
//
//  The execution order would be :
//  A - B - C - A - B - C - A - B - C - C - C - D
function setCursorLoopStart(cursor, numberRepetition) {
	if (cursor.flag.needsResetLoopParameters) cursor.navigation.totalNumberRepetitions = 1;
	// Initialize a loop (loop start index & number of repetitions) if :
	// 1. A number of repetition greater than 1 is specified in the block's settings,
	// 2. The cursor is not currently in a loop (thus the number of reptition left is <= 1)
	// 3. The current index is not the start index of a previous loop (to avoid resetting a loop twice)
	if (numberRepetition > 1 && cursor.current.numberRepetition <= 1 && cursor.current.index !== cursor.navigation.indexLoopStart) {
		cursor.navigation.totalNumberRepetitions = numberRepetition;
		cursor.navigation.indexLoopStart = cursor.current.index;
		cursor.current.numberRepetition = numberRepetition;
	}
}

// The cursor will loop to the start of the pile and use the content corresponding to the index named "piledContentIndex"
// Let A, B, C and D be three blocks, that are not instruction blocks.
//
//              A                                   B                               C                               D
//  ------------------------------  ------------------------------  ------------------------------  ------------------------------
//  | type: XYZ                  |  | type: XYZ                  |  | type: XYZ                  |  | type: XYZ                  |
//  |           ...              |  |           ...              |  |           ...              |  |           ...              |
//  | midiFileName: [0, 1]       |  | midiFileName: [0, 1]       |  | midiFileName: [0, 1, 2]    |  | midiFileName: [0, 1, 2]    |
//  | videoFileName: [0, 1]      |  | videoFileName: [0, 1]      |  | videoFileName: [0, 1, 2, 3]|  | videoFileName: [0, 1, 2]   |
//  | folloedBy: true            |  | folloedBy: true            |  |                            |  |                            |
//  ------------------------------  ------------------------------  ------------------------------  ------------------------------
//
//  The execution order would be :
//  A[0] - B[0] - C[0] - A[1] - B[1] - C[1] - D[0] - D[1] - D[2]
function setCursorMediaDepilingStart(cursor, listOfArrays, maxStackedContent) {

	// Count the number of piled media elements of each type and detemine the maximum number of piled content.
	let maxNumberElementsPiled = 0;
	for (const array of listOfArrays) {
		const numberElementsPiled = Array.isArray(array) ? array.length : 0;
		maxNumberElementsPiled = Math.max(numberElementsPiled, maxNumberElementsPiled);
		if (maxStackedContent)
			maxNumberElementsPiled = Math.min(maxStackedContent, maxNumberElementsPiled);
	}

	// Initialize the number of piled media content (playable media pile index & number of medias) if :
	// 1. There is more than one media content element (midi/video) (so the total index > 1),
	// 2. The cursor is not currently depiling a content pile (thus the number of piled content left is 1 or 0)
	// 3. The current index is not the start index of a previous pile (to avoid depiling a pile twice)
	// 4. The current index is beyond the loop end (in order to not start a loop of depilement within a group of blocks)
	if (
		maxNumberElementsPiled > 1 &&
		cursor.navigation.lastPiledContentIndex <= 1 &&
		cursor.current.index !== cursor.navigation.indexPileStart &&
		cursor.current.index > cursor.navigation.indexGroupEnd
	) {
		cursor.navigation.indexPileStart = cursor.current.index;
		cursor.navigation.lastPiledContentIndex = maxNumberElementsPiled;
	}
}

function setCursorNextStep(cursor, followedBy, loopEnd) {

	const numberRepetitionsLeftInLoop = cursor.current.numberRepetition;
	const isLooping = numberRepetitionsLeftInLoop > 1;

	// Updating the next index
	// If the block is followed by the next block, we will necessarily go to the next block and we know that we are within a group of blocks
	if ((followedBy && !loopEnd) || (!isLooping && loopEnd)) {
		cursor.navigation.indexNext = cursor.current.index + 1;
	}

	// If the block is not followed by another block, it is necesserily the end of a group of blocks
	// If there remains reptitions: We loop back to the start of the loop
	else if (numberRepetitionsLeftInLoop > 1) {
		cursor.navigation.indexNext = cursor.navigation.indexLoopStart;
	}

	// If there remains content to depile, reset the loop start in order to be able to loop again with the new media content
	else if (cursor.navigation.lastPiledContentIndex > 1) {
		cursor.navigation.indexNext = cursor.navigation.indexPileStart;
		cursor.navigation.indexLoopStart = defaultState.UNSET_INDEX;
	}

	// By default, the next block is the following block
	else {
		cursor.navigation.indexNext = cursor.current.index + 1;
	}
}

function determineGroupEnd(flow, cursor) {
	const cursorCopy = assignCursor(flow, cursor);
	const currentBlock = blockHandler.getCurrentBlock(flow, cursorCopy);
	while (currentBlock.followedBy && !cursorCopy.flag.isBeyondEnd) {
		moveCursorNext(flow, cursorCopy);
	}
	cursor.navigation.indexGroupEnd = cursorCopy.current.index;

	// HACK
	// Set the flag indicating whether or not the piled content should be maintained
	cursor.navigation.mustMaintainPiledContentIndex = Boolean(currentBlock.mustMaintainPiledContentIndex);
}
