// import { log } from '@/_helpers';

export default {
	EMPTY_THOROUGH_LOG_LIST,
	EMPTY_THOROUGH_LOG,
};

function EMPTY_THOROUGH_LOG_LIST() {
	return [];
}

function EMPTY_THOROUGH_LOG() {
	return {};
}
